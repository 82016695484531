import request from "../utils/request";

/**
 * 登录
 * @param data
 * @returns {AxiosPromise}
 */
export const login = data =>
    request({
        url: "/ophApi/auth/login",
        method: "post",
        data: {
            customerTypeIds: 1,
            ...data
        }
    });

export const getCodeImg = params =>
    request({
        url: "/ophApi/checkCode/getCheckCode",
        method: "POST",
        data: {
            customerTypeIds: 1,
            ...params
        }
    })