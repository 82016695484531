/**
 * txt：要加密的字段
 * key:秘钥
 *
 * @return {string}
 */
const sm4 = require('sm-crypto').sm4
const key = '736d5979746f6e674178756e32303233'
export const encrypt = (txt) => {
  let encryptData = sm4.encrypt(txt, key) // 加密结果
  return encryptData
}
