<template>
    <div class="login">
        <div class="box">
            <p class="tit">登录</p>
            <div class="bom">
              <div class="ti txt" v-if="isfalse">
                {{txt}}
              </div>
                <div class="ti">
                    <span>账号</span>
                    <input type="text" v-model="name" placeholder="请输入账号">
                </div>
                <div class="ti">
                    <span>密码</span>
                    <input type="password" v-model="pass" placeholder="请输入账号">
                </div>
              <div class="ti">
                <span>验证码</span>
                <input type="text" v-model="code" placeholder="请输入验证码">
                <div class="codeimg" @click="climg">
                  <img :src="codeImg" alt="">
                </div>
              </div>
                <button @click="login">登录</button>
            </div>
        </div>
    </div>
</template>
<script>
import { Login, getCodeImg } from '@/api/home'
import Cookies from 'js-cookie'
import { encrypt } from '@/utils/crypto.js'
import {login} from "../api/home";

export default {
  data(){
    return{
      isfalse: false,
      name: '',
      pass: '',
      code: '',
      codeImg:'',
      txt: '账号或密码错误'
    }
  },
  created() {
    let needReload = this.$route.params.needReload;
    if(needReload && needReload === 1) {
      window.location.reload();
    }
  },
  mounted() {
    this.getImg();
  },
  methods: {
    async getImg(){
      Cookies.remove('JSESSIONID', { path: '/ophApi' });
      Cookies.remove('JSESSIONID', { path: '/' });
      let res = await getCodeImg();
      var img = "data:image/png;base64," + res;
      this.codeImg = img;
    },
    climg(){
      this.getImg()
    },
    async login() {
        if(!this.code) {
          this.isfalse =true
          this.txt ='请输入验证码'
          return
        }
        if(!this.name || !this.pass) {
            this.isfalse =true
            this.txt ='请输入账号密码'
            return
        }
        let loginForm = {
          saasId: "1000",
          username: this.name,
          password: encrypt(this.pass),
          cryptoType: "sm4",
          loginTenantId: 1000,
          needMenu: true,
          code: this.code,
        };

        login(loginForm).then(res => {
          this.$router.push({name: 'Home'});
        }).catch(err => {
          this.getImg();
        });
      }
    }
}
</script>
<style scoped>
  .codeimg {
    width: 55%;
    height: 45px;
    margin: 10px auto;
    border: rgba(122,116,122,0.98);
  }
  .codeimg img{
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
    .login {
        height: 100vh;
        font-size: 20px;
        text-align: center;
    }
    .box {
        background-color: #ffffff;
        border: 1px solid #ccc;
        width: 440px;
        height: 400px;
        position: relative;
        left: 50%;
        margin-left: -220px;
        top: 35%;
        margin-top: -200px;
    }
    .tit {
        font-size: 20px;
        line-height: 40px;
        border-bottom: 1px solid #cccccc;
        margin-top: 0;
    }
    .txt {
        color: #E6A23C;
    }
    .bom {
        position: relative;
    }
    .ti {
        margin-bottom: 20px;
    }
    .ti span {
        width: 60px;
        display: inline-block;
        text-align: right;
        margin-right: 20px;
    }
    .ti input {
        width: 260px;
        height: 40px;
        outline: none;
        padding-left: 10px;
        border: 1px solid #303133;
        border-radius: 4px;
        font-size: 18px;
    }
    button {
        width: 200px;
        height: 40px;
        border: 0;
        outline: none;
        text-align: center;
        line-height: 40px;
        background: #409EFF;
        color: #ffffff;
        border-radius: 5px;
        margin-left: 30px;
    }
</style>